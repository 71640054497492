<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }" >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Mantenedores</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click=";[hide()]" />
      </div>

      <!-- BODY -->
      <div v-for="(item, index) in children">

        <div class="d-flex align-items-center px-2 py-1 link-maintainer" @click="goToRoute(item.route)" v-if="item.id">
          <feather-icon class="ml-1 mr-1 cursor-pointer" :icon="item.icon || 'CircleIcon'" size="20"/>
          <h5 class="mb-0">{{ item.title }}</h5>
        </div>
        <hr v-else>
        
      </div>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import router from '@/router'


// import StoresService from '@/services/StoresService'
import vSelect from 'vue-select'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
},
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      user_roles: JSON.parse(localStorage.getItem('userData')).role_user[0],
      userData: JSON.parse(localStorage.getItem('userData')),
      children: [
      /* {
        id: 'mantenedor-usuarios',
        title: 'Usuarios',
        route: 'usuarios',
        icon:'UserIcon'
      }, */
      {
        id: 'mantenedor-co-dueño',
        title: 'Co-Dueño',
        route: 'co-dueño',
        icon:'UserCheckIcon'
      },
      {
        id: '',      
      },
      {
        id: 'mantenedor-area',
        title: 'Area',
        route: 'areas',
        icon:'GridIcon'
      },
      {
        id: '',      
      },
      {
        id: 'mantenedor-categoria',
        title: 'Categoria',
        route: 'categoria',
        icon:'TagIcon'
      },
      {
        id: 'mantenedor-sub-categoria',
        title: 'Sub Categoria',
        route: 'sub-categoria',
        icon:'TagIcon'
      },
      {
        id: '',      
      },
    
      // {
      //   id: 'mantenedor-dueño',
      //   title: 'Dueño',
      //   route: 'dueño'
      // },
      
      {
        id: 'mantenedor-especialidades',
        title: 'Especialidades',
        route: 'especialidades',
        icon:'FolderIcon'
      },
      {
        id: 'mantenedor-localizacion',
        title: 'Localización',
        route: 'localizacion',
        icon:'Share2Icon'
      },
      {
        id: '',      
      },
      {
        id: 'mantenedor-actividad',
        title: 'Actividad',
        route: 'actividad',
        icon:'ListIcon'
      },      
      {
        id: 'mantenedor-periodos',
        title: 'Periodos',
        route: 'periodos',
        icon:'ClockIcon'
      },
      {
        id: 'mantenedor-proyectos',
        title: 'Proyectos',
        route: 'proyectos',
        icon:'InfoIcon'
      }
    ]
      
    }
  },
  mounted() {
    if(this.userData != undefined){
      if (this.user_roles.is_restriction) {
         if (this.user_roles.role_restriction != 'admin') {
          console.log('aqui');
          this.children.splice(13, 1);
        }
        if(this.user_roles.role_restriction == 'admin'){
          this.children.push({
            id: 'mantenedor-agrupaciones',
            title: 'Agrupaciones',
            route: 'agrupaciones',
            icon:'FolderIcon'
          })
        }
      }

      if (this.user_roles.is_lesson && this.user_roles.role_lesson === 'admin-lesson' && !this.user_roles.is_restriction) {
        console.log('aqui 145');
        this.children = [
          /* {
            id: 'mantenedor-usuarios',
            title: 'Usuarios',
            route: 'usuarios',
            icon:'UserIcon'
          }, */
          {
            id: 'mantenedor-division',
            title: 'Divisiones',
            route: 'division',
            icon:'ListIcon'
          },
          {
            id: 'mantenedor-tipo-obra',
            title: 'Tipo de Obras',
            route: 'tipo-obra',
            icon:'ListIcon'
          },
          {
            id: 'mantenedor-especialidades-lecciones',
            title: 'Especialidades de Lecciones',
            route: 'especialidades-lecciones',
            icon:'ListIcon'
          },
          {
            id: 'mantenedor-tipificacion',
            title: 'Tipificaciones',
            route: 'tipificacion',
            icon:'ListIcon'
          }
        ];
      }
      if(this.user_roles.is_lesson && this.user_roles.role_lesson === 'admin-lesson'){
        console.log("PUEDE VERLOOOOOOO")
        let itemTemp = {
          id: 'mantenedor-usuarios-lecciones',
          title: 'Usuarios Lecciones Aprendidas',
          route: 'usuarios-lecciones',
          icon:'UserIcon'
        }
        this.children.unshift(itemTemp);

      }
      if(this.user_roles.is_restriction){
        console.log("PUEDE VERLOOOOOOO")
        let itemTemp = {
          id: 'mantenedor-usuarios',
          title: 'Usuarios',
          route: 'usuarios',
          icon:'UserIcon'
        }
        this.children.unshift(itemTemp);

      }

    }
  },
  methods: {
   
    goToRoute(route){
      this.$router.push({ name: route })
    }
    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.link-maintainer:hover{
  background-color: #001955;
  cursor: pointer;
  h5{
    color:white;
  }
};
hr {
  margin-top: 0.1rem !important;
  margin-bottom: 0.1rem !important;
}


</style>
