<template>
  <!-- eslint-disable -->
  <div class="w-100 p-2">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <b-card-header class="px-2 py-2 pb-0">
          <b-row class="w-100 h-100">
            <b-col md="3">
              <label>Agrupación</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="grouppings"
                label="description"
                :reduce="grouppings => grouppings.id"
                placeholder="Agrupación"
                v-model="agrupacion"
                @input="filter"
                class="select-obra"
              />
            </b-col>
            <b-col md="3">
              <div class="d-flex justify-content-center justify-content-md-start align-items-center mt-1 h-100">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="mr-1"
                  @click.prevent="clean"
                >
                  <span class="align-middle">Ver Todos</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
      </b-card>
      <b-row v-if="records.length > 0">
        <b-col
          v-for="(item, index) in records"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="content_card_project xl-2"
          @click="selectProyect(item)"
        >
          <div class="card_project d-flex flex-column mb-2">
            <div class="card_img">
              <img :src="item.url ? apiurl + item.url : sideImg" :alt="'imagen_project_' + index" />
            </div>
            <div class="card_content px-3 py-2 d-flex flex-column">
              <span class="card_content_sub">Proyecto - {{ item.code }}</span>
              <span class="card_content_title mb-2">{{ item.description }}</span>
              <span v-if="item.groupping" class="card_content_sub mb-2">Grupo: {{ item.groupping.description }}</span>
              <span v-else class="card_content_sub mb-2">Sin grupo</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="p-2" v-if="records.length > 0">
        <b-row>
          <b-col sm="3">
            <b-form-group
              label-cols="4"
              label-cols-md="4"
              label-size="md"
              label="Entradas"
              label-for="input-md"
            >
              <b-form-select
                v-model="showEntrie"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="entries"
                @change="changeSizePage"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3" class="mt-75" style="font-size: 12px">
            <span> {{ totalElements }} Registros en total</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="showEntrie"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="cambioPagina"
              pills
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="15" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="15" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <h3>Sin proyectos</h3>
      </div>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
const APIURL = process.env.APIURLFILE
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ProjectsService from '@/services/ProjectsService'
import GrouppingService from '@/services/GrouppingService'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import router from '@/router/index'
import VueBus from 'vue-bus';
import { bubbleToast } from '../router/bubble.js'; // Ajusta la ruta según la ubicación de index.js

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueBus);

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      fields: [
        { key: 'code', label: 'Codigo', sortable: false },
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'contract_type', label: 'Tipo Contrato', sortable: false },
        { key: 'amount', label: 'Monto', sortable: false },
        { key: 'participation', label: 'Participación', sortable: false },
        { key: 'manager', label: 'Gerente', sortable: false },
        { key: 'actions', label: 'Acciones' }
      ],
      form: {
        code: null,
        description: '',
        contract_type: '',
        manager: '',
        amount: null,
        participation: '',
        users: []
      },
      project_id: JSON.parse(localStorage.getItem('project_id')),
      records: [],
      documentSelect: [],
      arrayFilters: [],
      agrupacion: null,
      grouppings: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      apiurl: APIURL,
      sideImg: require('@/assets/images/access/default.png')
    }
  },
  components: {
    vSelect
  },
  mounted() {
    this.getData()
    this.getGroups()
    console.log("PROJECT ID", this.project_id)
    if (this.project_id != null) {
      let userData = getUserData()
      const nameRouter = getHomeRouteForLoggedInUser(userData ? userData : null)
      console.log("NAME ROUTER", nameRouter)
      router.push({ name: nameRouter })
      const projectId = localStorage.getItem('project_id');
      const userId =localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).id:null
      const userRole =localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction:null

      bubbleToast(projectId,userId,userRole);
    }
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    async getData() {
    
      let offset = this.showEntrie * (this.currentPage - 1)
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
     
      if (respProyectos.status) {
        this.records = respProyectos.data.rows
        this.totalElements = respProyectos.data.responseFilter.total_rows
      }
    },
    
    selectProyect(item) {
      if (item.id != null) {
      
        localStorage.setItem('project_id', item.id)
        localStorage.setItem('project_name', item.description)
        let userData = getUserData()
        const nameRouter = getHomeRouteForLoggedInUser(userData ? userData : null)
      console.log("NAME ROUTER", nameRouter)

        router.push({ name: nameRouter })
        const projectId = localStorage.getItem('project_id');
        const userId =localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).id:null
        const userRole =localStorage.getItem('userData') ?  JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction:null
      
        bubbleToast(projectId,userId,userRole);

        /* bubbleToast(); */

        // this.$swal({
        //   title:
        //     '¿Seguro que desea seleccionar el proyecto ' +
        //     item.code +
        //     ' - ' +
        //     item.description +
        //     '?',
        //   icon: 'warning',
        //   showCancelButton: true,
        //   confirmButtonText: 'Si, seleccionar',
        //   customClass: {
        //     confirmButton: 'btn btn-primary',
        //     cancelButton: 'btn btn-outline-danger ml-1'
        //   },
        //   buttonsStyling: false
        // }).then((result) => {

        // })
      }
    },
    async getGroups(){
      const url = '?limit=100000'
      const resp = await GrouppingService.getGroupping(url, this.$store)
      this.grouppings = resp.data.rows
      this.grouppings.unshift({ id: "sin grupo", description: 'Sin Agrupación' })
      console.log('resp GROUPPING', resp)
    },
    
    async filter() {
      this.arrayFilters = []
      if (this.agrupacion != '' && this.agrupacion != null) {
        if(this.agrupacion == 'sin grupo'){
          this.arrayFilters.push({
            keyContains: 'groupping_id',
            key: 'equals',
            value: null
          })
        }else{
          this.arrayFilters.push({
            keyContains: 'groupping_id',
            key: 'equals',
            value: this.agrupacion
          })
        }
      }
      console.log('arrayFilter', this.arrayFilters)
      this.getData()
    },
    clean() {
      this.agrupacion = null
      this.arrayFilters = []
      this.getData()
    }
  },
  created() {
    // Escucha un evento personalizado llamado 'executeGetData'
    this.$bus.on('executeGetData', () => {
      // Llama a la función getData pasando las variables
      this.getData();
    });
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card_project {
  border-radius: 15px;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  // background: linear-gradient(118deg, #001955, rgba(0, 25, 85, 0.7))
  background: linear-gradient(
    0deg,
    rgba(246, 249, 252, 1) 0%,
    rgba(234, 242, 249, 1) 48%,
    rgba(223, 235, 248, 1) 100%
  );
}
.card_project .card_content .card_content_sub {
  color: #59ccd0;
  font-weight: 600;
}
.card_project .card_content .card_content_title {
  font-size: 18px;
  font-weight: 600;
  color: #001955;
}
.card_img img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 15px 15px 0px 0px;
  background-color: #fff !important;
}
.card_content .btn_edit:hover {
  background-color: transparent !important;
}
.card_content .btn_edit {
  color: #001955;
  font-weight: 600;
}
@media (min-width: 1500px) {
  .xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
}
.content_card_project {
  cursor: pointer;
}
.content_card_project:hover {
  translate: 0 -15px;
  transition: all 0.2s ease-out;
}
</style>
